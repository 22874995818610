import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

// styles
import "./Navbar.css";
import BookIcon from "../assets/book_icon.svg";

const Navbar = () => {
    const { logout, isPending } = useLogout();
    const { user } = useAuthContext();
    return (
        <div className="navbar">
            <ul>
                <li className="logo">
                    <img src={BookIcon} alt="book logo"></img>
                    <span>The Project Manager</span>
                </li>

                {!user && (
                    <>
                        <li>
                            <Link to="/login">Login</Link>
                        </li>
                        <li>
                            <Link to="/signup">Signup</Link>
                        </li>
                    </>
                )}
                {user && (
                    <li>
                        {!isPending && (
                            <button className="btn" onClick={logout}>
                                Logout
                            </button>
                        )}
                        {isPending && (
                            <button className="btn" disabled>
                                Logging out...
                            </button>
                        )}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Navbar;
