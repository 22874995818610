import ProjectList from "../../components/ProjectList";
import { useCollection } from "../../hooks/useCollection";
import ProjectFilter from "./ProjectFilter";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

// styles
import "./Dashboard.css";

const Dashboard = () => {
    const { user } = useAuthContext();
    const { documents, error } = useCollection("projects");
    const [currentFilter, setCurrentFilter] = useState("all");

    const changeFilter = (newFilter) => {
        setCurrentFilter(newFilter);
    };

    // perform filter only if documents are valid
    const projects = documents
        ? documents.filter((document) => {
              switch (currentFilter) {
                  case "all":
                      return true;
                  case "mine":
                      let assignedToMe = false;
                      document.assignedUsersList.forEach((u) => {
                          if (u.id === user.uid) {
                              assignedToMe = true;
                          }
                      });
                      return assignedToMe;
                  case "development":
                  case "marketing":
                  case "design":
                  case "sales":
                      console.log(document.category, currentFilter);
                      return document.category === currentFilter;
                  default:
                      return true;
              }
          })
        : null;

    return (
        <div>
            <h2 className="page-title">Dashboard</h2>
            {error && <p className="error">{error}</p>}
            {projects && (
                <ProjectFilter
                    currentFilter={currentFilter}
                    changeFilter={changeFilter}
                />
            )}
            {projects && <ProjectList projects={projects} />}
        </div>
    );
};

export default Dashboard;

// NOTE: managing state in the Dashboard allows the ProjectList component to refresh when the
// state for the selected filter changes. this allows us to fetch fresh data and the
// list will automatically show fresh results.
