import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAdIl4YhAPJ-ASV8AgAxR5Q__29VkH7TYs",
    authDomain: "ninja-dojo-management.firebaseapp.com",
    projectId: "ninja-dojo-management",
    storageBucket: "ninja-dojo-management.appspot.com",
    messagingSenderId: "168104541338",
    appId: "1:168104541338:web:1fa4a4c57544c4876cb323",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, projectStorage, timestamp };
