import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { timestamp } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { useFirestore } from "../../hooks/useFirestore";

// styles
import "./Create.css";

// lookups
const categories = [
    { value: "development", label: "Development" },
    { value: "design", label: "Design" },
    { value: "sales", label: "Sales" },
    { value: "marketing", label: "Marketing" },
];

const Create = () => {
    const history = useHistory();
    const { addDocument, response } = useFirestore("projects");
    const { documents } = useCollection("users");
    const [users, setUsers] = useState([]);
    const { user } = useAuthContext();

    // format users list
    useEffect(() => {
        // prevent execution when component loads for first time.
        if (documents) {
            const options = documents.map((user) => {
                return { value: user, label: user.displayName };
            });
            setUsers(options);
        }
    }, [documents]);
    // form field values
    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [category, setCategory] = useState("");
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [formError, setFormError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormError(null);

        // form validation
        if (!category) {
            setFormError("Please select a project category");
            return;
        }
        if (assignedUsers.length < 1) {
            setFormError("Please assign the project to at least 1 user");
            return;
        }

        // project creator
        const createdBy = {
            displayName: user.displayName,
            photoURL: user.photoURL,
            id: user.uid,
        };

        // flat assignedUsers list
        const assignedUsersList = assignedUsers.map((u) => {
            return {
                displayName: u.value.displayName,
                photoURL: u.value.photoURL,
                id: u.value.id,
            };
        });

        const project = {
            name,
            details,
            dueDate,
            category: category.value,
            dueDate: timestamp.fromDate(new Date(dueDate)),
            comments: [],
            createdBy,
            assignedUsersList,
        };

        await addDocument(project);
        // once done, redirect to home.
        if (!response.error) {
            history.push("/");
        }
    };

    return (
        <div className="create-form">
            <h2 className="page-title">Create a new project</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    <span>Project name:</span>
                    <input
                        required
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                </label>
                <label>
                    <span>Project details:</span>
                    <textarea
                        required
                        type="text"
                        onChange={(e) => setDetails(e.target.value)}
                        value={details}
                    ></textarea>
                </label>
                <label>
                    <span>Set due date:</span>
                    <input
                        required
                        type="date"
                        onChange={(e) => setDueDate(e.target.value)}
                        value={dueDate}
                    />
                </label>
                <label>
                    <span>Project category:</span>
                    <Select
                        options={categories}
                        onChange={(option) => setCategory(option)}
                    />
                </label>
                <label>
                    <span>Assign to:</span>
                    <Select
                        options={users}
                        onChange={(option) => setAssignedUsers(option)}
                        isMulti
                    />
                </label>
                <button className="btn">Add Project</button>
                {formError && <p className="error">{formError}</p>}
            </form>
        </div>
    );
};

export default Create;
