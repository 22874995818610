import { useEffect, useState } from "react";
import { projectFirestore } from "../firebase/config";

export const useDocument = (collection, id) => {
    const [document, setDocument] = useState(null);
    const [error, setError] = useState(null);

    // realtime data for document
    useEffect(() => {
        const ref = projectFirestore.collection(collection).doc(id);
        const unsub = ref.onSnapshot(
            (snapshot) => {
                if (snapshot.data()) {
                    setDocument({ ...snapshot.data(), id: snapshot.id });
                    setError(null);
                } else {
                    setError("document does not exist");
                }
            },
            (err) => {
                console.log(err);
                setError("failed to get document");
            }
        );
        return () => unsub();
    }, [collection, id]);
    // run the code whenever the collection or id changes.

    return { document, error };
};
