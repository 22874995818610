import { useState } from "react";
import { useSignup } from "../../hooks/useSignup";

// styles
import "./Signup.css";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [thumbnail, setThumbnail] = useState(null);
    const [thumbnailError, setThumbnailError] = useState(null);
    const { signup, isPending, error } = useSignup();

    const handleSubmit = (e) => {
        e.preventDefault();
        signup(email, password, displayName, thumbnail);
    };

    const handleFileCHange = (e) => {
        setThumbnail(null);

        // take first file if multiples are updloaded
        let selectedFile = e.target.files[0];

        if (!selectedFile) {
            setThumbnailError("Please select a file");
            return;
        }

        if (!selectedFile.type.includes("image")) {
            setThumbnailError("Selected file must be an image");
            return;
        }
        if (selectedFile.size > 200000) {
            setThumbnailError("Image file size must be less than 200kb");
            return;
        }

        // upload passes all validation, reset error and set image.
        setThumbnailError(null);
        setThumbnail(selectedFile);
        console.log("thumbnail updated!");
    };

    return (
        <form className="auth-form" onSubmit={handleSubmit}>
            <h2>Sign up</h2>
            <label>
                <span>email:</span>
                <input
                    required
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
            </label>
            <label>
                <span>password:</span>
                <input
                    required
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                />
            </label>
            <label>
                <span>name:</span>
                <input
                    required
                    type="text"
                    onChange={(e) => setDisplayName(e.target.value)}
                    value={displayName}
                />
            </label>
            <label>
                <span>profile thumbnail:</span>
                <input required type="file" onChange={handleFileCHange} />
                {thumbnailError && (
                    <div className="error">{thumbnailError}</div>
                )}
            </label>
            {!isPending && <button className="btn">Sign up</button>}
            {isPending && (
                <button className="btn" disabled>
                    Loading...
                </button>
            )}
            {error && <div className="error">{error}</div>}
        </form>
    );
};
export default Signup;
